import {Injectable} from '@angular/core';
import {TranslocoService} from '@ngneat/transloco';

import {AppStateService} from '@core/modules';
import {
  footToMetersCalculation,
  kilometersToMilesCalculation,
  metersToFootCalculation,
  milesToKilometersCalculation,
} from '@unitSystem/helpers';
import {UNIT_SYSTEMS_LIST} from '@unitSystem/static';

import {UnisSystemTranslateItems, UnitSystem, UnitSystemItem, UnitSystemTranslateKey} from '../../models';

let service: UnitSystemService;

export const translateUnit = (key: UnisSystemTranslateItems): string => service?.translate(key);

export const metersToFoot = (meters: string | number, digits = 2) => service.metersToFoot(meters, digits);
export const footToMeters = (foot: number, digits = 2) => service?.footToMeters(foot, digits);

export const kilometersToMiles = (kilometers: string | number, digits = 2) => service?.kilometersToMiles(kilometers, digits);
export const milesToKilometers = (miles: number, digits = 2) => service?.milesToKilometers(miles, digits);

@Injectable({providedIn: 'root'})
export class UnitSystemService {
  /**
   * Map for caching length calculation
   *
   * @private
   */
  private readonly lengthCache = new Map<number, number | string>();
  /**
   * Map for caching distance calculation
   *
   * @private
   */
  private readonly distanceCache = new Map<number, number | string>();

  /**
   * Getting current unit system which enabled in system and tied to user
   */
  get unitSystem(): UnitSystem {
    return this.appStateService.unitSystem;
  }

  private get isMetricCurrentSystem(): boolean {
    return this.unitSystem === UnitSystem.METRIC;
  }

  constructor(
    private readonly translocoService: TranslocoService,
    private readonly appStateService: AppStateService,
  ) {
    service = this;
  }

  translate(key: string): string {
    const unitKey: UnitSystemTranslateKey = this.unitSystem === UnitSystem.IMPERIAL ? 'imperial' : 'metrical';
    return this.getTranslations(`unitSystem.${unitKey}.${key}`);
  }

  metersToFoot(meters: string | number, digits = 2): number | string {
    if (this.isMetricCurrentSystem || (meters === null || meters === undefined)) {
      return meters;
    }
    const value = typeof meters === 'number' ? meters : parseInt(meters, 10);
    if (isNaN(value)) {
      console.error('Invalid value while converting meters to foot');
      return meters;
    }
    if (this.lengthCache.has(value)) {
      return this.lengthCache.get(value) as number;
    }
    const result = parseFloat((metersToFootCalculation(value)).toFixed(digits));
    this.lengthCache.set(value, result);
    return result;
  }

  footToMeters(foot: number, digits = 2): number {
    // if (this.un)
    if (this.isMetricCurrentSystem) {
      return foot;
    }
    return parseFloat((footToMetersCalculation(foot)).toFixed(digits));
  }

  kilometersToMiles(kilometers: string | number, digits = 2): number | string {
    if (this.isMetricCurrentSystem || (kilometers === null || kilometers === undefined)) {
      return kilometers;
    }
    const value = typeof kilometers === 'number' ? kilometers : parseInt(kilometers, 10);
    if (isNaN(value)) {
      console.error('Invalid value while converting kilometers to miles');
      return kilometers;
    }
    if (this.distanceCache.has(value)) {
      return this.distanceCache.get(value) as number;
    }
    const result = parseFloat((kilometersToMilesCalculation(value)).toFixed(digits));
    this.distanceCache.set(value, result);
    return result;
  }

  milesToKilometers(miles: number, digits = 2): number {
    if (this.isMetricCurrentSystem) {
      return miles;
    }
    return parseFloat((milesToKilometersCalculation(miles)).toFixed(digits));
  }

  unitSystemsList(): UnitSystemItem[] {
    const key = 'unitSystem';
    return UNIT_SYSTEMS_LIST.map(item => new UnitSystemItem(item.value, `${key}.${item.title}`, `${key}.${item.list}`));
  }

  private getTranslations(key: string): string {
    return this.translocoService.translate(key);
  }
}
