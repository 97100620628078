import {NgModule} from '@angular/core';
import {TRANSLOCO_SCOPE, TranslocoModule} from '@ngneat/transloco';

import {FootUnitPipe, MilesUnitPipe, UnitSystemLabelPipe} from './pipes';

@NgModule({
  declarations: [
    UnitSystemLabelPipe,
    FootUnitPipe,
    MilesUnitPipe,
  ],
  imports: [
    TranslocoModule,
  ],
  exports: [
    UnitSystemLabelPipe,
    FootUnitPipe,
    MilesUnitPipe,
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'unit-system',
      multi: true,
    },
  ],
})
export class UnitSystemModule {
}
