import {Pipe, PipeTransform} from '@angular/core';

import {UnitSystemService} from '../../../unit-system/services';

@Pipe({
  name: 'unitSystemLabel',
  pure: true,
})
export class UnitSystemLabelPipe implements PipeTransform {

  constructor(private readonly unitSystemService: UnitSystemService) {
  }

  transform(value: string | unknown): string {
    if (typeof value !== 'string') {
      console.error('Value for UnitSystemLabelPipe should be a string');
      return '';
    }
    return this.unitSystemService.translate(value);
  }

}
