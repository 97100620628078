import {Pipe, PipeTransform} from '@angular/core';

import {metersToFoot} from '../../services';

@Pipe({
  name: 'footUnit',
  pure: true,
})
export class FootUnitPipe implements PipeTransform {

  transform(value: string | number): unknown {
    return metersToFoot(value);
  }

}
