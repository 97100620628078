/**
 * Coefficient for converting <b>meters</b> to <b>foot</b>
 */
export const FOOT_COEFFICIENT = 3.281;
/**
 * Coefficient for converting <b>kilometers</b> to <b>miles</b>
 */
export const MILE_COEFFICIENT = 1.61;

const toFloatNumber = (value: number, digits: number = 2): number => parseFloat(value.toFixed(digits));

/**
 * Converting meters to foot
 *
 * @param value - meters value
 */
export const metersToFootCalculation = (value: number): number => toFloatNumber(value * FOOT_COEFFICIENT);
/**
 * Converting foot for meters
 *
 * @param value - foot value
 */
export const footToMetersCalculation = (value: number): number => toFloatNumber(value / FOOT_COEFFICIENT);

/**
 * Converting kilometers to miles
 *
 * @param value - kilometers value
 */
export const kilometersToMilesCalculation = (value: number): number => toFloatNumber(value / MILE_COEFFICIENT);
/**
 * Converting miles to kilometers
 *
 * @param value - miles value
 */
export const milesToKilometersCalculation = (value: number): number => toFloatNumber(value * MILE_COEFFICIENT);
