import {Pipe, PipeTransform} from '@angular/core';

import {kilometersToMiles} from '../../services';

@Pipe({
  name: 'milesUnit',
  pure: true,
})
export class MilesUnitPipe implements PipeTransform {

  transform(value: string | number): number | string {
    return kilometersToMiles(value);
  }

}
